import * as React from 'react';
import { Link } from 'gatsby';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLiterature,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import routes from '../../../routes';

import infographic from '../../../images/educational-corner/blog10/infografika.png';
import communicationSpeechLanguage from '../../../images/educational-corner/blog10/Komunikacija-govor-jezik.png';
import organisationEarlyIntervention from '../../../images/educational-corner/blog10/Organizacija-rane-intervencije.png';
import earlyIntervention from '../../../images/educational-corner/blog10/Rana-intervencija-logoped.png';

const EarlyInterventionSpeechDevelopment = () => {
  return (
    <PageNew
      title="KOKOLINGO – Rana Intervencija i Jezično-Govorni Razvoj"
      description="Rana intervencija je namijenjena djeci od rođenja do najdulje sedme
      godine života koja su u riziku za razvoj odstupanja ili već imaju teškoće u razvoju."
      headline="Rana intervencija i jezično-govorni razvoj"
    >
      <Text as="p">
        Jezično-govorni razvoj možemo pratiti prema određenim{' '}
        <Link
          to={routes.LANGUAGE_DEVELOPMENT}
          title="Razvoj jezika i govora"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>miljokazima</b>
        </Link>
        . Odstupanje od tipičnog obrasca razvoja može se dogoditi zbog
        prisutnosti određenog poremećaja, poput oštećenja sluha, poremećaja iz
        spektra autizma, intelektualnih teškoća itd. Kako bi se osigurala
        odgovarajuća podrška djeci s teškoćama, ali i njihovim obiteljima,
        potrebna je provedba usluga rane intervencije.
      </Text>
      <Text as="h2">Rana intervencija</Text>
      <Text as="p">
        <b>
          Rana intervencija usluga je namijenjena djeci od rođenja do najdulje
          sedme godine života koja su u riziku za razvoj odstupanja ili već
          imaju utvrđene teškoće u razvoju.{' '}
        </b>
        To znači da se podrška i terapija mogu pružiti ako se utvrdi da postoji
        samo rizik za razvoj određene teškoće, odnosno ne čeka se postavljanje
        dijagnoze da bi se određena usluga pružila. Rana intervencija nije
        usmjerena samo na pružanje podrške djeci nego i njihovim obiteljima. Ona
        podrazumijeva različite profile stručnjaka koji stvaraju partnerske
        odnose s roditeljima i pružaju im podršku. U cijelom procesu rane
        intervencije bitno je da stručnjaci osnažuju roditelje kako bi mogli
        ulaziti u interakcije sa svojom djecom. Mnoga istraživanja pokazuju
        koliko su rane interakcije između roditelja i djece važne za razvoj u
        ranom djetinjstvu.
      </Text>
      <BlogImage
        imgSrc={earlyIntervention}
        imgAlt="Rana intervencija djeteta kod logopeda"
        imgTitle="Rana intervencija"
      />
      <Text as="h2">Organizacija rane intervencije</Text>
      <Text as="p">
        U sklopu rane intervencije pružaju se razne usluge djeci različitih
        profila, kao i njihovim obiteljima. Psiholozi, radni terapeuti,
        neonatolozi, logopedi, edukacijski rehabilitatori, fizioterapeuti i
        mnogi drugi stručnjaci pružaju svoje usluge koje mogu biti organizirane
        na razne načine, ovisno o djetetovim potrebama.
        <b>
          {' '}
          Iako se može činiti kao veoma raznolik sustav, rana intervencija ima
          nekoliko temeljnih principa u organizaciji rada:
        </b>
        <ul>
          <li>rano prepoznavanje rizika i rana dijagnostika</li>
          <li>usmjerenost na obitelj</li>
          <li>poštovanje kulturnih različitosti</li>
          <li>
            povezanost i koordinacija stručnjaka, odnosno različitih sustava –
            socijalna skrb, zdravstvo, obrazovanje
          </li>
          <li>usluge se temelje na znanstveno utemeljenim principima rada</li>
          <li>evaluacija usluga</li>
          <li>međuovisnost svih sastavnica</li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={organisationEarlyIntervention}
        imgAlt="Organizacija rane intervencije"
        imgTitle="Organizacija rane intervencije"
      />
      <Text as="h2">Komunikacija, govor i jezik</Text>
      <Text as="p">
        O <i>komunikaciji</i>, <i>govoru</i> i <i>jeziku</i> često se govori te
        se značenja ovih pojmova ponekad preklapaju. To su bliski, ali ne i
        istoznačni pojmovi. Bitno ih je razlikovati u kontekstu rane
        intervencije, odnosno dječjeg razvoja.
        <b>
          {' '}
          Komunikacija je proces razmjene obavijesti i temelj za usvajanje
          jezika i govora. Nužna je za suradnju s drugima, igru, učenje,
          intelektualni razvoj itd.{' '}
        </b>
        Jezik je dogovoreni sustav znakova i pravila koji se koristi za
        sporazumijevanje ljudi na određenom području. Možemo ga podijeliti na
        ekspresivni jezik, odnosno mogućnost izražavanja te na receptivni jezik,
        odnosno mogućnost razumijevanja poruka. Govor je optimalno sredstvo za
        prenošenje jezičnih informacija, a temelji se na urednom razvoju
        komunikacije i jezika.
      </Text>
      <BlogImage
        imgSrc={communicationSpeechLanguage}
        imgAlt="Komunikacija, govor, jezik"
        imgTitle="Komunikacija, govor, jezik"
      />
      <Text as="p">
        Tijekom rane intervencije poticat ćemo i komunikaciju i jezik i govor
        jer je njihov razvoj međusobno isprepleten.
        <b>
          {' '}
          Zadatak stručnjaka, u ovom slučaju logopeda, jest pomoći roditelju u
          poticanju djetetove komunikacije kroz svakodnevne rutine i igre.{' '}
        </b>
        Primjerice, u ranoj dobi fokus rada može biti na poboljšanju jasnoće
        djetetovih zahtjeva i na povećanju njihove učestalosti, a manje na
        učenju novih riječi. Tome je tako jer djeca neće imati koristi od učenja
        novih riječi ako ne ulaze u interakcije s okolinom. Budući da je svako
        dijete individualno, načini poticanja razlikovat će se od djeteta do
        djeteta, od obitelji do obitelji. Na početku rada bitno je da logoped
        izradi individualizirani plan poticanja i podrške koji će se sastojati
        od više manjih koraka koji se povezuju u veću cjelinu. U tom planu
        logoped će uključiti strategije za poticanje komunikacijskog i
        jezično-govornog razvoja kroz svakodnevnu komunikaciju.
        Individualizirani plan potrebno je u određenim vremenskim točkama
        revidirati s obzirom na mogućnosti i napredak djeteta. Poticanje treba
        započeti što ranije, na svakodnevnoj bazi, a samim time roditelj i
        dijete imat će više prilika za interakcije. S roditeljima treba graditi
        partnerski odnos, usmjeravati ih i ohrabrivati. Budući da je rana
        intervencija interdisciplinarni pristup, različiti stručnjaci također će
        savjetovati roditelje i pratiti djetetov napredak, ali i međusobno
        surađivati. Usluge koje se pružaju djetetu i obitelji moraju biti
        sveobuhvatne i timski razrađene.
      </Text>
      <Text as="h2">Rana intervencija u hrvatskoj</Text>
      <Text as="p">
        <b>
          U Hrvatskoj su usluge stručnjaka različitih profila neravnomjerno
          raspoređene, odnosno najčešće ih je moguće dobiti u većim gradovima.{' '}
        </b>
        Najčešće su te usluge odvojene pa nema timskog rada i koordinacije među
        stručnjacima. Zbog takve situacije roditelji često “lutaju” u potrazi za
        informacijama i pomoći, što dovodi do stresa, anksioznosti i
        nezadovoljstva. Dobro je znati da je usluga rane intervencije definirana{' '}
        <a
          href="https://gov.hr/moja-uprava/obitelj-i-zivot/socijalna-skrb/prava-i-usluge-za-osobe-s-invaliditetom-iz-sustava-socijalne-skrbi/usluga-rane-intervencije/1809"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>Zakonom o socijalnoj skrbi</b>
        </a>{' '}
        kao socijalna usluga koja se pruža do pet sati tjedno kod nekog
        utvrđenog razvojnog rizika ili razvojne teškoće djeteta. Iako je kao
        usluga definirana, cjelokupni sustav rane intervencije u Hrvatskoj još
        nije uspostavljen. Mnoge su inicijative za uspostavljanje takvog
        sustava, posebice inicijativa{' '}
        <a
          href="https://hurid.hr/about-us/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>Hrvatske udruge za ranu intervenciju u djetinjstvu - HURID</b>
        </a>
        , kao i postojanje brojnih pružatelja usluga u različitim sektorima.
        Kako bi se roditeljima olakšala potraga za adekvatnom podrškom udruga
        HURID u suradnji s UNICEF-om izradila je tražilicu{' '}
        <a
          href="https://raniklik.hr"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>raniKLIK</b>
        </a>{' '}
        putem koje roditelji mogu pronaći podatke o pružateljima usluga rane
        intervencije na području odabranoga grada ili županije. Cilj je stranice
        i povezati stručnjake različitih sustava kako bi se osigurao timski
        pristup korisnicima njihovih usluga. Ovaj vrijedni alat stoga može biti
        od velike pomoći ne samo roditeljima nego i stručnjacima.
      </Text>
      <Text as="p">
        Rana intervencija proces je pružanja usluga djeci kojoj je utvrđen rizik
        za određeno odstupanje ili djeci koja imaju određenu razvojnu teškoću.
        Ova je usluga orijentirana na pružanje podrške cijeloj obitelji, a
        posebice roditeljima. Iako je sustav rane intervencije u Hrvatskoj još u
        povojima, inicijativa poput HURID-ove tražilice raniKLIK pozitivan je
        pomak prema tome da se smanji “lutanje” roditelja i osigura potrebna
        podrška i terapija onoj djeci kojoj je najpotrebnija.
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Ljubešić, Marta (2012). Rana intervencija kod komunikacijskih i
            jezično-govornih odstupanja. <i>Paediatria Croatica, 56</i> (1),
            202-206.
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default EarlyInterventionSpeechDevelopment;
